/* TODO Add site wide styles */


li{
    list-style-type: none;
}

nav {
    border: 1px solid black;
    /* width: fit-content; */
    padding: 0px 40px 50px 10px;
    border-radius: 10px;
    background-image: url('./components/images/nav-bar-background.png');
    height: 100vh;
}



.app-main{
    display: flex;
    flex-direction: row;
     background-image:url('./components/images/background-filler.png');
     background-repeat: repeat;
 }

textarea {
    resize:none;
}

button{
    cursor:pointer;
}


* {
    font-family: 'Gaegu', cursive;
}
