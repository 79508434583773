#modal {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    z-index: 9999;
  }

  #modal-background {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9999;
  }

  #modal-content {
    position: absolute;
    background-color:#ffece1;
    border-radius: 10px;
    padding-left: 40px;
    padding-right: 40px;
    width: 20%;
    z-index: 9999;
    overflow-y: scroll;
    height: fit-content;
    padding-bottom: 1rem;
  }

  .form-header h1{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .form-body{
    display: flex;
    flex-direction: column;
  }


  .form-input{
    height: 60px;
    padding-bottom: 50px;
  }

  .notebook-form-input{
    font-size: 20px;
    padding-bottom: 0px;
  }
