.home-button img{
    height: 400px;
    width: 400px;
    margin-top: -160px;
    margin-left: -75px;

}

.nav-button{
    font-size: 40px;
    height: 80px;
    width: 150px;
    border-radius: 45px;
    padding: 5px;
    margin: 20px;
    background-color: #b8c4b5;
}

.repo-nav-button{
    font-size: 30px;
    height: 80px;
    width: 150px;
    border-radius: 45px;
    padding: 5px;
    margin: 20px;
    background-color: #b8c4b5;

}
.logout-button{
    height: 280px;
    width: 200px;
    position: absolute;

    cursor: pointer;
}



/* notes */


.my-notes-header{
    display: flex;
    height:300px;
    margin-bottom:-140px;
}

.create-new-note i{
    padding-left: 50px;
    padding-top: 0px;
}

.home-notes-list{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 60vw;
}
.notes-list{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
}
.all-notes-note-container{
    border: 1px solid black;
    padding: 11px;
    overflow: auto;
    width: 25%;
    display: flex;
    width: fit-content;
    align-items: center;
    padding: 0px 20px 10px 20px;
    border-radius: 10px;
    background-color: white;
    margin-left: 5vh;
    margin-top: 20vh;
    background-color: #b8c4b5;

}

.logout-button {
    font-family:'Peace Sans', sans-serif;

}


.card-container h3{
    padding-left: 0;
    font-size: 30px;
    margin-top: 0;
    font-weight: bolder;
}

.card-container{
    /* border: 1px solid black; */
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 200px;
    width: 215px;
    margin: 20px;
    background-color: #eaccba;
    /* color: white; */
}


.login-header img{
        height: 300px;
    width: 300px;
    margin-top: -140px;
    margin-bottom: -100px;
}


.login-graphic img{
    height:400px;
    width: 400px;
    margin-left:30px;
    margin-top: -15px;

}

.login-form-main{
    background-image: url('../components/images/sticky-note.png');
    height: 500px;
    width: 500px;
    text-align: center;
    padding-top: 135px;
    margin-left: 25%;
    margin-top: 5%;
    background-repeat: no-repeat;
    border:none;
}

.sign-up-main{
    background-image: url('../components/images/sticky-note.png');
    height: 500px;
    width: 500px;
    text-align: center;
    padding-top: 135px;
    margin-left: 20%;
    margin-top: 5%;
    background-repeat: no-repeat;
    border:none;
}

.sign-up-header img{
    margin-top: -93px;
    margin-bottom: -112px;
    height: 225px;
}

.notebook-table {
    overflow-y: scroll;
    background-color: #b8c4b5;
    height: 40vh;
    padding: 10px;
    background-color: #b8c4b5;
    font-size: 30px;
    margin-left: 1rem;
  }

th, td {
    padding: 8px;
    text-align: left;
    font-size: 3vh;

  }

.single-note-details{
    border: 1px solid black;
    padding: 20px;
    background: white;
    border-radius: 10px;
    height: 100vh;
    width: 40%;
    background-image:url('./images/my-notes-container-background.png')
}

.note-header{
    background: #99A67F;
    border-radius: 10px;
    width: 10vw;
    margin-left: 1rem;
    margin-bottom: 20px;
    padding-bottom: 0px;
    border:1px solid black;
    height: 15vh;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

}

.note-body{
    display: flex;
    margin-top: 11rem;
    flex-direction: column;
}

.notebook-name{
    margin-top: 0;
    margin-bottom: 0;
    font-size: 25px;
}

.edit-note-errors{
    background: white;
}

.edit-note-title-input{
    width: 20vw;
    height: 40px;
    border-radius: 10px;
    padding-top: 0;
    font-size: xxx-large;
    font-weight: bolder;
    background-color: #99A67F;
    color: white;
    overflow: auto;
}

.edit-delete-buttons{
    float: right;
    background-color: #99a67f;
    height: 7vh;
    width: 4vw;
    padding: 0vw;
    margin-bottom: 2rem;
    border: 1px solid black;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.edit-note-container{
    text-align: center;
    margin-top: -8rem;
}

.edit-note-form-text{
      font-size: 30px;

}

.rabbit{
    height:20vh;
    border-radius:11rem;
}

.react-quill{
    width: 30vw;
    border-radius: 10px;
    text-align: left;
    height: 50vh;
    margin-top: 0px;
    background-color: #99A67F;
    color:white;
    border:1px solid black;
    cursor: pointer;
}
.ql-editor p{
    font-size: 30px;
    height: 80%;
}

.ql-container.ql-snow{
    font-family: Helvetica, Arial, sans-serif;
    font-size: 13px;
    height: 80%;
    margin: 0px;
    position: relative;
    border: none;
}

.login-form-main button{
    cursor: pointer;
    width: 6vw;
    height: 4vh;
    font-size: large;
    border-radius: 14px;
    border: 1px solid black;
    margin-top: 1.5rem;
    background-color: #b8c4b5;
}
.sign-up-main input{
    margin-left:1rem
}

.sign-up-main button{
    cursor: pointer;
    width: 6vw;
    height: 4vh;
    font-size: large;
    border-radius: 14px;
    border: 1px solid black;
    margin-top: 1.5rem;
    background-color: #b8c4b5;
}

.button.form-button{
    height: 2rem;
    width: 6rem;
    font-size: 20px;
    margin-top: 3%;
    border-radius: 10px;
    margin-right: 5%;
     margin-left: 3%;
    background-color: #DB9970;
    color: white;
}


.notebooks-list{
    width:45vw;
    margin-left:1rem;
    margin-right: 1rem;
    margin-top: 1rem;
}

/* all lists styling*/

.my-to-do-header{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.my-to-do-header h1{
    margin-right: 4rem;
}

.lists-container {
    background-color: white;
    /* background-image: url('./images/nav-bar-background.png'); */
    /* background-repeat: none; */
    border: 1px solid black;
    border-radius: 10px;
    height: 40vh;
    width: 15vw;
    margin-left: 1rem;
    margin-top: 1rem;
    padding: 1rem;
}
.list-content {
    width: 12vw;
    display: inline-block;
    border: 1px solid black;
    background-color: #FDD6B3;
    padding: 0.5rem;
    border-radius: 5px;
}
.prev-arrow {
    position: absolute;
    left: 0;
    bottom: -6rem;
    z-index: 1;
    cursor: pointer;
  }

  .next-arrow {
    position: absolute;
    right: 0;
    bottom: -6rem;
    z-index: 1;
    cursor: pointer;
  }

  .edit-task-input{
    width: auto;
    height: 40px;
    border-radius: 10px;
    padding-top: 0;
    font-size: xxx-large;
    font-weight: bolder;
    background-color: #99A67F;
    color: white;
}
