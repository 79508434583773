.section-1{
    background: #e1edde;
    border: 1px solid black;
    border-radius: 20px;
    padding: 10px;
    margin-left: 20px;
    display:flex;
    flex-direction: column;
}

.section-1 img{
    margin-left: auto;
    height: 200px;
}

.graphic-3 img{
    height: 20vh;
    margin-top: -18vh;
}

.section-2{
    background: white;
    border: 1px solid black;
    border-radius: 20px;
    padding: 10px;
    margin-left: 20px;
    background-color: #ffece1;
    margin-top: 80px;
}

.section-2 img{
    height: 100px;
    width: 100px;
}
